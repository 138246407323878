import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PagingDto } from '../../dtos';

interface PagingProps<T> {  // ジェネリック型 T をインターフェースで定義
  data: PagingDto<T>;  // PagingDto も T を受け取るように定義
  onPageChange: (page: number, pageSize: number) => void;
  defaultPage?: number;
  defaultPageSize?: number;
}

// React.FC<T> としてジェネリック型を指定
const Paging = <T,>({ data, onPageChange, defaultPage = 1, defaultPageSize = 50 }: PagingProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  // クエリパラメータから page と pageSize を取得
  useEffect(() => {
    let pageParam = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
    pageParam = isNaN(pageParam) ? defaultPage : pageParam;
    let pageSizeParam = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
    pageSizeParam = isNaN(pageSizeParam) ? defaultPageSize : pageSizeParam;
    setCurrentPage(pageParam);
    setPageSize(pageSizeParam);
  }, [searchParams, defaultPageSize, defaultPage]);

  const totalPages = Math.ceil(data.totalCount / pageSize);

  // ページ変更時にクエリパラメータを更新
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page, pageSize);
    // クエリパラメータを更新
    setSearchParams({ page: page.toString(), pageSize: pageSize.toString() });
  };

  return (
    <div className="flex justify-center items-center space-x-2 mt-4 text-sm">
      <button
        className={`px-3 py-1 border border-gray-300 rounded-full ${currentPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-black'}`}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        前のページ
      </button>
      <span className="text-black">{currentPage} / {totalPages}</span>
      <button
        className={`px-3 py-1 border border-gray-300 rounded-full ${currentPage === totalPages ? 'text-gray-500 cursor-not-allowed' : 'text-black'}`}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        次のページ
      </button>
    </div>
  );
};

export default Paging;