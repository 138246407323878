import React, { useEffect, useState } from 'react';
import { CommandResult } from '../../dtos';
import { useUpdate } from '../../utils/api';
import { ENDPOINT } from '../../const';
import { Textarea } from '../atoms/Textarea';
import { PrimaryButton } from '../atoms/PrimaryButton';

interface Props {
  targetCommandResult: CommandResult;
  onComplete: (player: CommandResult) => void;
}

export const CommandResultEditForm: React.FC<Props> = ({ targetCommandResult, onComplete }) => {
  const { teamId, commandId, parentId } = targetCommandResult;
  const [content, setContent] = useState('');
  const { execUpdate: createCommandResult, submitting } = useUpdate<{ content: string, parentId: string }, CommandResult>({
    method: 'POST',
    onComplete: async (data: CommandResult) => {
      onComplete(data);
    },
  });

  useEffect(() => {
    if (targetCommandResult) {
      setContent(targetCommandResult.content);
    }
  }, [targetCommandResult]);

  return (
    <>
      <form className="space-y-4">
        <Textarea placeholder={''} rows={10} errorMessages={content.length > 0 ? [] : ['入力してください']} value={content} onChange={setContent} />
        <PrimaryButton loading={submitting} disabled={submitting || content.length === 0} fullWidth text={'結果を変更する'} onClick={async () => {
          await createCommandResult({ content, parentId: parentId }, ENDPOINT.COMMANDS_COMMAND_RESULT(teamId as string, commandId as string));
        }} />
      </form>
    </>
  );
};
