import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { CommandDetailDto, CommandNewDto, PlayerDetailDto, CommentDto, CommandDto } from '../../../dtos';
import { ENDPOINT, STORAGE } from '../../../const';
import { toast } from 'react-toastify';
import { LinkButton } from '../../atoms/LinkButton';
import { Loading } from '../../atoms/Loading';
import EditButton from '../../atoms/EditButton';
import { TextInput } from '../../atoms/TextInput';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { Modal } from '../../organisms/Modal';
import { useFetch, useUpdate } from '../../../utils/api';
import { SubTitle } from '../../atoms/SubTitle';
import { COMMAND_STATUS } from '../../../enums/command-status';
import CommandStatusTag from '../../atoms/CommandStatusTag';
import { CommandFlow } from '../../organisms/CommandFlow';
import { CommandIcon } from '../../atoms/CommandIcon';
import { ResultContent } from '../../organisms/ResultContent';
import { CommandResultIcon } from '../../atoms/CommandResultIcon';
import { SelectInput } from '../../atoms/SelectInput';
import dayjs from 'dayjs';
import { SecondaryButton } from '../../atoms/SecondaryButton';
import { Textarea } from '../../atoms/Textarea';
import CommentItemList from '../../molecules/CommentItemList';
import { TeamMembersContext } from '../../templates/AuthTeamLayout';
import { CommentSectionTitle } from '../../molecules/CommentSectionTitle';
import { CommentCount } from '../../atoms/CommentCount';
import CommandItemList from '../../molecules/CommandItemList';
import Paging from '../../molecules/Paging';

const defaultPage = 1;
const defaultPageSize = 5;

export const CommandDetail: React.FC = () => {
  const { teamId, playerId, commandId, commandResultId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const teamMembers = useContext(TeamMembersContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const hasLatestHash = useMemo(() => location.hash === '#latest', [location.hash]);

  const [showFullContent, setShowFullContent] = useState(false);
  const [isSummaryEditModalOpen, setIsSummaryEditModalOpen] = useState(false);
  const [summary, setSummary] = useState('');
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const { execFetch: fetchCommandDetail, data: commandDetail } = useFetch<CommandDetailDto>();
  const { execFetch: fetchCommandsByParentId, data: commands } = useFetch<CommandDto[]>();
  const { execFetch: fetchPlayer, data: player } = useFetch<PlayerDetailDto>();
  const { execFetch: fetchComments, data: comments } = useFetch<CommentDto[]>();
  const { execUpdate: updateSummary, submitting } = useUpdate<{ summary: string }, CommandDetailDto>({
    method: 'PUT',
    onComplete: async () => {
      toast.success('要約を変更しました。');
      setSummary('');
      await fetchCommandsByParentId(ENDPOINT.PLAYER_PARENT_COMMAND(teamId as string, playerId as string, commandDetail?.command.parentId as string));
    },
  });
  const { execUpdate: createComment, submitting: commenting } = useUpdate<{ content: string, tableName: string, primaryId: string }, PlayerDetailDto>({
    method: 'POST',
    onComplete: async () => {
      toast.success('コメントしました。');
      setComment('');
      await fetchComments(ENDPOINT.TEAM_COMMENTS(teamId as string, 'commands', commandId as string));
    },
  });


  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
    { label: '詳細', to: `/teams/${teamId}/players/${playerId}` },
    { label: 'リクエスト詳細', to: `/teams/${teamId}/players/${playerId}/commands/${commandId}` },
  ];

  // URLから取得可能なデータを取得(commandDetail, player)
  useEffect(() => {
    if (teamId && playerId && commandId) {
      let page = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
      page = isNaN(page) ? defaultPage : page;
      let pageSize = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
      pageSize = isNaN(pageSize) ? defaultPageSize : pageSize;
      fetchCommandDetail(`${ENDPOINT.PLAYER_COMMAND(teamId as string, playerId as string, commandId as string)}?page=${page}&pageSize=${pageSize}`);
      fetchPlayer(ENDPOINT.PLAYER(teamId as string, playerId as string));
      fetchComments(ENDPOINT.TEAM_COMMENTS(teamId as string, 'commands', commandId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, teamId, playerId, commandId]);

  // 取得したデータから取得が必要なデータを取得(commands)
  useEffect(() => {
    if (commandDetail) {
      fetchCommandsByParentId(ENDPOINT.PLAYER_PARENT_COMMAND(teamId as string, playerId as string, commandDetail?.command.parentId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandDetail, teamId, playerId, commandId]);

  // 取得したデータからデータのセット(summary)
  useEffect(() => {
    if (commands && commandDetail) {
      setSummary(commands.find(d => d.id === commandDetail.command.parentId)?.summary ?? '');
    }
  }, [commands, commandDetail, setSummary]);

  // ページのリダイレクト処理
  useEffect(() => {
    const isOnlyCommandPath = /^\/teams\/[^/]+\/players\/[^/]+\/commands\/[^/]+$/.test(location.pathname);
    if (isOnlyCommandPath) {
      if (hasLatestHash && commands) {
        const latestCommandId = commands[0].id;
        navigate(`/teams/${teamId}/players/${playerId}/commands/${latestCommandId}`, { replace: true });
        return;
      }
      // commandIdとcommandDetailの値が一致しない瞬間に想定外の挙動になるため、明示的にif文に条件として追加している
      if (!hasLatestHash && commandDetail?.command.status === COMMAND_STATUS.COMPLETED && commandId === commandDetail?.command.id) {
        const latestCommandResultId = commandDetail?.results[0].id;
        navigate(`/teams/${teamId}/players/${playerId}/commands/${commandId}/results/${latestCommandResultId}`, { replace: true });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commands, hasLatestHash, commandDetail, teamId, playerId, commandId, navigate, location.pathname]);

  // ポーリング処理
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    if (commandDetail?.command.status === COMMAND_STATUS.EXECUTING) {
      intervalId = setInterval(() => {
        fetchCommandDetail(ENDPOINT.PLAYER_COMMAND(teamId as string, playerId as string, commandId as string));
      }, 3000);
    }

    // クリーンアップ関数でインターバルを解除
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandDetail, teamId, playerId, commandId]);

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const parentCommand = useMemo(() => {
    return commands?.find(d => d.id === commandDetail?.command.parentId);
  }, [commands, commandDetail]);

  if (!commands || !commandDetail || hasLatestHash || !parentCommand || !comments) {
    return <Loading />;
  }

  return (
    <div className='space-y-8'>
      <div>
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <div className='flex items-center space-x-2'>
            <Title title={parentCommand.summary ?? ''}>
              <CommandIcon />
            </Title>
            <EditButton onClick={() => setIsSummaryEditModalOpen(true)} disabled={commandDetail.command.status === COMMAND_STATUS.EXECUTING} />
          </div>
          <div className='flex items-center justify-between'>
            <SelectInput
              options={commands.map((item) => ({
                label: `v${item.versionNo} - ${dayjs(item.createdAt).format('YYYY/M/D H:mm')}`,
                value: item.id,
              }))}
              value={commandDetail.command.id}
              onChange={(v) => {
                navigate(`/teams/${teamId}/players/${playerId}/commands/${v}`);
              }}
            />
            <div className="flex flex-col items-end">
              <p className="flex space-x-1">
                <span className="text-gray-500 text-xs">作成者:</span>
                <span className="text-gray-500 text-xs">{teamMembers?.find((member) => member.id === parentCommand.createdBy)?.name}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='space-y-4'>
        <div className='flex items-center justify-between'>
          <CommandStatusTag status={commandDetail.command.status} />
          <CommentCount count={comments.length} clickable />
        </div>
        <div className='flex items-center space-x-2'>
          <SubTitle title="リクエスト内容" />
          <EditButton onClick={() => {
            const commandNew: CommandNewDto = {
              baseSteps: [...commandDetail.steps],
            }
            localStorage.setItem(STORAGE.COMMAND_NEW_DATA, JSON.stringify(commandNew));
            navigate(`/teams/${teamId}/players/${commandDetail.command.playerId}/commands/${commandDetail.command.id}/edit`);
          }} />
        </div>
        <div>
          <div className={`whitespace-pre-line`}>
            {
              showFullContent ? commandDetail.command.content : `${commandDetail.command.content.slice(0, 100)}${commandDetail.command.content.length > 100 ? '...' : ''}`
            }
          </div>
          {/* すべて見るボタン */}
          {commandDetail.command.content.length > 100 && (
            <div className="mt-2">
              <LinkButton
                onClick={() => toggleShowFullContent()}
                text={showFullContent ? '折りたたむ' : 'すべて見る'}
              />
            </div>
          )}
        </div>
      </div>

      {
        commandDetail.command.status !== COMMAND_STATUS.FAILED ? (
          <>
            <div className='space-y-4'>
              <div className='flex items-center space-x-2'>
                <SubTitle title="リクエスト結果">
                  <CommandResultIcon />
                </SubTitle>
                <EditButton onClick={() => {
                  navigate(`/teams/${teamId}/players/${commandDetail.command.playerId}/commands/${commandDetail.command.id}/results/${commandResultId}/edit`);
                }} />
              </div>
              {
                commandResultId && (
                  <div className='flex'>
                    <SelectInput
                      options={commandDetail.results.map((item) => ({
                        label: `v${item.versionNo} - ${dayjs(item.createdAt).format('YYYY/M/D H:mm')}`,
                        value: item.id,
                      }))}
                      value={commandResultId}
                      onChange={(v) => {
                        navigate(`/teams/${teamId}/players/${playerId}/commands/${commandId}/results/${v}`);
                      }}
                    />
                  </div>
                )
              }
              {
                commandDetail.command.status === COMMAND_STATUS.EXECUTING ? (
                  <div className="flex flex-col items-center space-y-2">
                    <ProgressBar />
                    <span>リクエスト処理中...</span>
                  </div>
                ) : commandDetail.command.status === COMMAND_STATUS.FAILED ? (
                  <div>
                    {/* リクエスト処理が失敗しました。しばらくしてから再度操作してください。 */}
                  </div>
                ) : (
                  <div className="transition-opacity duration-700 ease-in-out opacity-0 animate-fade-in">
                    <ResultContent content={commandDetail.results.find(d => d.id === commandResultId)?.content ?? ''} />
                  </div>
                )
              }
            </div>
            {
              player && commandDetail && commandDetail.command.status !== COMMAND_STATUS.EXECUTING && (
                <>
                  <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-4 sm:w-auto w-full'>
                    <div className='flex items-center justify-end space-x-2 max-w-screen-sm mx-auto'>
                      <div className="flex-shrink-0 w-auto sm:text-base text-sm">
                        <SecondaryButton
                          fullWidth
                          text="コメントする"
                          onClick={() => setIsCommentModalOpen(true)}
                        />
                      </div>
                      <div className="flex-shrink-0 w-auto sm:text-base text-sm">
                        <PrimaryButton fullWidth text="続けてリクエストする" onClick={() => {
                      const commandNew: CommandNewDto = {
                        baseSteps: [...commandDetail.steps, {
                          tableName: 'commands',
                          primaryId: commandDetail.command.id,
                        }, {
                          tableName: 'command_results',
                          primaryId: commandDetail.results.find(d => d.id === commandResultId)?.id || '',
                        }],
                      }
                      localStorage.setItem(STORAGE.COMMAND_NEW_DATA, JSON.stringify(commandNew));
                          navigate(`/teams/${teamId}/players/${commandDetail.command.playerId}/commands/new`);
                        }} />
                      </div>
                    </div>
                  </div>

                  <div className='space-y-4'>
                    <div className='flex items-center space-x-2'>
                      <SubTitle title="続けて作成したリクエスト" >
                        <CommandIcon />
                      </SubTitle>
                    </div>
                    {
                      commandDetail.followingCommands.items.length > 0 ? (
                        <>
                          <Paging
                            data={commandDetail.followingCommands}
                            onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                            defaultPage={defaultPage}
                            defaultPageSize={defaultPageSize}
                          />
                        <CommandItemList
                          data={commandDetail.followingCommands}
                          onItemClick={(item) => {
                            navigate(`/teams/${teamId}/players/${playerId}/commands/${item.id}`);
                          }}
                        />
                          <Paging
                            data={commandDetail.followingCommands}
                            onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                            defaultPage={defaultPage}
                            defaultPageSize={defaultPageSize}
                          />
                        </>

                      ) : (
                        <p className='text-gray-500'>続けて作成したリクエストはありません。</p>
                      )
                    }
                  </div>

                  <div className='space-y-4'>
                    <div className='flex items-center space-x-2'>
                      <CommentSectionTitle />
                    </div>
                    {
                      comments.length > 0 ? (
                        <CommentItemList
                          items={comments}
                        />
                      ) : (
                        <p className='text-gray-500'>コメントはありません。</p>
                      )
                    }
                  </div>

                  <div className='space-y-4'>
                    <div className='flex items-center space-x-2'>
                      <SubTitle title="リクエストの流れ" />
                    </div>
                    <CommandFlow player={player} steps={commandDetail.steps} />
                  </div>

                </>
              )
            }
          </>
        ) : (
          <div>
            リクエスト処理が失敗しました。しばらくしてから再度操作してください。
          </div>
        )
      }

      <Modal isOpen={isSummaryEditModalOpen} onClose={() => setIsSummaryEditModalOpen(false)} title="要約を変更する">
        <form className="space-y-4">
          <TextInput value={summary} onChange={(value) => setSummary(value)} placeholder="要約" />
          <PrimaryButton
            disabled={summary.length === 0 || submitting}
            loading={submitting}
            fullWidth
            text="要約を変更する"
            onClick={async () => {
              await updateSummary({ summary }, ENDPOINT.PLAYER_COMMAND(teamId as string, parentCommand.playerId, parentCommand.id));
              setIsSummaryEditModalOpen(false);
            }}
          />
        </form>
      </Modal>

      <Modal isOpen={isCommentModalOpen} onClose={() => setIsCommentModalOpen(false)} title="コメントする">
        <form className="space-y-4">
          <Textarea value={comment} onChange={(value) => setComment(value)} />
          <PrimaryButton
            disabled={comment.length === 0 || commenting}
            loading={commenting}
            fullWidth
            text="コメントする"
            onClick={async () => {
              await createComment({ content: comment, tableName: 'commands', primaryId: commandDetail.command.id }, ENDPOINT.TEAM_COMMENTS(teamId as string, 'commands', commandDetail.command.id));
              setIsCommentModalOpen(false);
            }}
          />
        </form>
      </Modal>
    </div>
  );
};

const ProgressBar: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(prevProgress => (prevProgress + 10) % 110);
    }, 750);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-64 h-4 bg-gray-200 rounded-full overflow-hidden">
      <div
        className="h-full bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-500 ease-in-out"
        style={{ width: `${Math.min(progress, 100)}%` }}
      ></div>
    </div>
  );
};