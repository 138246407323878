import React from 'react';
import { FcNext } from "react-icons/fc";

// SubTitle のプロパティ型定義
interface SubTitleProps {
  children?: React.ReactNode;
  title: string;
}

export const SubTitle: React.FC<SubTitleProps> = ({ title, children }) => {
  return (
    <div className="flex items-center space-x-1">
      {/* 左側の装飾 */}
      {
        children ?? (
          <FcNext className='size-6' />
        )
      }
      {/* タイトル表示部分 */}
      <h2 className="text-lg md:text-xl font-semibold text-gray-700">
        {title}
      </h2>
    </div>
  );
};