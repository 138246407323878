// src/components/FormField.tsx

import React, { ReactNode } from 'react';

interface FormFieldProps {
  id: string;
  label?: string;
  required?: boolean;
  helpText?: string;
  children: ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({
  id,
  label,
  required = false,
  helpText,
  children
}) => {
  return (
    <div className="mb-4">
      {label && (
        <label htmlFor={id} className="block mb-2 font-bold text-gray-700">
          {label}
          {required && <span className="ml-1 text-gray-500">*</span>}
        </label>
      )}
      <div className="w-full">
        {children}
      </div>
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
};

export default FormField;