import React, { useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { CommentDto } from '../../dtos';
import { TeamMembersContext } from '../templates/AuthTeamLayout';
import { FaRegUserCircle } from "react-icons/fa";

interface CommentItemListProps {
  items: CommentDto[];
}

// URLを検出してリンクに変換する関数
const convertUrlsToLinks = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline">${url}</a>`);
};

// 改行とURLを処理する関数
const processContent = (content: string): string => {
  const withLinks = convertUrlsToLinks(content);
  return withLinks.replace(/\n/g, '<br>');
};

const CommentItemList: React.FC<CommentItemListProps> = ({ items }) => {
  const teamMembers = useContext(TeamMembersContext);

  return (
    <div className="space-y-2">
      {items.map((item) => (
        <div key={item.id} className="flex items-start gap-2.5">
          <FaRegUserCircle />
          <div className="flex flex-col w-full max-w-[1000px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <span className="text-sm font-semibold text-gray-900 dark:text-white">{teamMembers?.find(d => d.id === item.createdBy)?.name ?? 'unknown'}</span>
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">{dayjs(item.createdAt).format('YYYY/M/D H:mm')}</span>
            </div>
            <p
              className="text-sm font-normal py-2.5 text-gray-900 dark:text-white"
              dangerouslySetInnerHTML={{ __html: processContent(item.content) }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentItemList;