import React, { useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { CommandListItemDto, PagingDto } from '../../dtos';
import { TeamMembersContext } from '../templates/AuthTeamLayout';
import { CommandCount } from '../atoms/CommandCount';

interface CommandItemListProps {
  data: PagingDto<CommandListItemDto>;
  onItemClick: (item: CommandListItemDto) => void;
}

const CommandItemList: React.FC<CommandItemListProps> = ({ data, onItemClick }) => {
  const teamMembers = useContext(TeamMembersContext);

  return (
    <div>
      <ul className="divide-y divide-gray-300">
        {data.items.map((item) => (
          <li
            key={item.id}
            className="py-4 px-2 hover:bg-gray-100 cursor-pointer transition"
            onClick={() => onItemClick(item)}
          >
            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
              <div>
                <p className="text-gray-500 font-semibold text-xs">{item.playerSummary}</p>
                <p className="text-black font-bold">{item.summary}</p>
                <CommandCount count={item.followingCommandCount} />
              </div>
              <div className="flex flex-col items-end">
                <p className="text-gray-500 text-xs">{dayjs(item.createdAt).format('YYYY/M/D H:mm')}</p>
                <p className="flex space-x-1">
                  <span className="text-gray-500 text-xs">作成者:</span>
                  <span className="text-gray-500 text-xs">{teamMembers?.find((member) => member.id === item.createdBy)?.name}</span>
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommandItemList;