import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">StoryForge 利用規約</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">はじめに</h2>
        <p>本利用規約（以下、「本規約」といいます）は、合同会社Viable（以下、「当社」といいます）が提供するStoryForgeサービス（以下、「本サービス」といいます）の利用条件を定めるものです。ユーザーの皆様には、本規約に同意いただいた上で本サービスをご利用いただきます。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">定義</h2>
        <p>2.1 「ユーザー」とは、本サービスを利用する全ての個人または法人を指します。</p>
        <p>2.2 「コンテンツ」とは、本サービスを通じて生成されるテキスト、画像、その他の情報を指します。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">サービスの内容</h2>
        <p>3.1 本サービスは、AIを活用して漫画のストーリー、キャラクター設定、第一話の内容などを生成するものです。</p>
        <p>3.2 当社は、本サービスの内容を予告なく変更、追加、削除する権利を有します。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">アカウント</h2>
        <p>4.1 本サービスの利用にはLINEアカウントによるログインが必要です。</p>
        <p>4.2 ユーザーは、自身のアカウント情報の管理責任を負います。</p>
        <p>4.3 アカウントの不正使用によって生じた損害について、当社は一切の責任を負いません。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">利用料金と支払い</h2>
        <p>5.1 本サービスの利用料金は、当社のウェブサイトに掲載される料金プランに基づきます。</p>
        <p>5.2 支払いは、クレジットカードによる決済のみとします。</p>
        <p>5.3 無料トライアルの後、選択したプランに基づく課金が開始されます。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">知的財産権</h2>
        <p>6.1 本サービスを通じて生成されたコンテンツの著作権は、ユーザーに帰属します。</p>
        <p>6.2 ユーザーは、生成されたコンテンツを自由に使用、編集、商用利用する権利を有します。</p>
        <p>6.3 本サービス自体に関する知的財産権は、当社に帰属します。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">禁止事項</h2>
        <p>ユーザーは、以下の行為を行ってはなりません：</p>
        <ul className="list-disc list-inside">
          <li>7.1 法令または公序良俗に反する行為</li>
          <li>7.2 当社または第三者の知的財産権を侵害する行為</li>
          <li>7.3 本サービスの運営を妨害する行為</li>
          <li>7.4 他のユーザーまたは第三者に不利益を与える行為</li>
          <li>7.5 本サービスを不正に利用する行為</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">免責事項</h2>
        <p>8.1 当社は、本サービスの完全性、正確性、有用性を保証するものではありません。</p>
        <p>8.2 当社は、本サービスの利用によってユーザーに生じたいかなる損害についても責任を負いません。</p>
        <p>8.3 当社は、本サービスの中断、停止、終了、利用不能について一切の責任を負いません。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">サービスの変更・終了</h2>
        <p>当社は、ユーザーに事前に通知することなく、本サービスの内容を変更し、または提供を終了することができます。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">個人情報の取り扱い</h2>
        <p>当社は、ユーザーの個人情報を当社のプライバシーポリシーに従って取り扱います。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">規約の変更</h2>
        <p>当社は、本規約を随時変更することができます。変更後の規約は、当社ウェブサイトに掲載した時点で効力を生じるものとします。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">準拠法と管轄裁判所</h2>
        <p>本規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      </section>
    </div>
  );
};

export default TermsOfService;