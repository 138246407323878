import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">StoryForge プライバシーポリシー</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">はじめに</h2>
        <p>合同会社Viable（以下、「当社」といいます）は、StoryForgeサービス（以下、「本サービス」といいます）におけるユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます）を定めます。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">収集する情報</h2>
        <p>当社は、以下の情報を収集します：</p>
        <h3 className="text-xl font-semibold mt-4 mb-2">2.1 ユーザーが提供する情報</h3>
        <ul className="list-disc list-inside ml-4">
          <li>LINEアカウント情報（ユーザー名、プロフィール画像など）</li>
          <li>支払い情報（クレジットカード情報など）</li>
          <li>サービス利用時に入力される情報（ジャンル、漫画の概要など）</li>
        </ul>
        <h3 className="text-xl font-semibold mt-4 mb-2">2.2 自動的に収集される情報</h3>
        <ul className="list-disc list-inside ml-4">
          <li>IPアドレス</li>
          <li>デバイス情報</li>
          <li>利用ログ</li>
          <li>Cookieやその他の追跡技術により収集される情報</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">情報の利用目的</h2>
        <p>収集した情報は、以下の目的で利用します：</p>
        <ul className="list-disc list-inside ml-4">
          <li>本サービスの提供・維持・改善</li>
          <li>ユーザー認証</li>
          <li>カスタマーサポート</li>
          <li>利用料金の請求</li>
          <li>サービスに関する通知やお知らせの送信</li>
          <li>マーケティング及び広告配信</li>
          <li>不正利用の防止及びセキュリティの確保</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">情報の共有</h2>
        <p>当社は、以下の場合を除き、ユーザーの個人情報を第三者と共有しません：</p>
        <ul className="list-disc list-inside ml-4">
          <li>ユーザーの同意がある場合</li>
          <li>法令に基づく場合</li>
          <li>当社の権利や財産を保護する必要がある場合</li>
          <li>サービス提供に必要な外部委託先（決済代行業者など）と共有する場合</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">データの保護</h2>
        <p>当社は、ユーザーの個人情報を保護するために適切な技術的・組織的措置を講じます。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">データの保持期間</h2>
        <p>当社は、個人情報を、その利用目的の達成に必要な期間のみ保持します。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">ユーザーの権利</h2>
        <p>ユーザーは、自己の個人情報について、アクセス、訂正、削除、利用制限、データポータビリティの権利を有します。これらの権利行使を希望する場合は、当社にお問い合わせください。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">Cookieの使用</h2>
        <p>当社は、ユーザーエクスペリエンスの向上やサービスの改善のためにCookieを使用します。ユーザーはブラウザの設定でCookieを無効にすることができますが、一部のサービス機能が利用できなくなる可能性があります。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">子供のプライバシー</h2>
        <p>本サービスは13歳未満の子供を対象としていません。13歳未満の子供の個人情報を故意に収集することはありません。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">第三者リンク</h2>
        <p>本サービスには、第三者のウェブサイトへのリンクが含まれる場合があります。これらの第三者サイトのプライバシー慣行については、当社は責任を負いません。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">プライバシーポリシーの変更</h2>
        <p>当社は、本ポリシーを随時変更することがあります。重要な変更がある場合は、サービス上での通知や電子メールでお知らせします。</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">お問い合わせ</h2>
        <p>本ポリシーに関するご質問やお問い合わせは、以下の連絡先までお願いします：</p>
        <p>合同会社Viable<br />
          住所：東京都港区南青山２丁目２番１５号ウィン青山９４２<br />
          メール：support@viable.jp</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;