import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { CommandDto, CommandResult, CommandStep, CommandStepBase, PlayerDetailDto } from '../../dtos';
import { PlayerIcon } from '../atoms/PlayerIcon';
import { CommandIcon } from '../atoms/CommandIcon';
import { CommandResultIcon } from '../atoms/CommandResultIcon';
import dayjs from 'dayjs';
import { useFetch } from '../../utils/api';
import { ENDPOINT } from '../../const';
import { LinkButton } from '../atoms/LinkButton';
import { useNavigate } from 'react-router-dom';

interface CommandFlowProps {
  player: PlayerDetailDto;
  steps: (CommandStep | CommandStepBase)[];
}

export const CommandFlow: React.FC<CommandFlowProps> = ({ player, steps }) => {
  const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const { execFetch: fetchParentPlayer, data: parentPlayer } = useFetch<PlayerDetailDto>();

  useEffect(() => {
    fetchParentPlayer(ENDPOINT.PLAYER(player.teamId, player.parentId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  if (!parentPlayer) {
    return null;
  }

  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName='text-gray-500'
        date={dayjs(player.createdAt).format('YYYY/M/D H:mm')}
        iconStyle={{ background: '#fff', color: '#000' }}
        icon={<PlayerIcon />}
      >
        <p onClick={() => navigate(`/teams/${player.teamId}/players/${player.id}`)} className='cursor-pointer absolute -top-4 -left-2 px-1 bg-white text-blue-500 rounded-full transition-transform transform-gpu'>v{player.versionNo}</p>
        <div className='flex justify-between items-center space-x-2'>
          <h2 className='font-semibold'>{parentPlayer.summary}</h2>
        </div>
        <div className='text-sm flex justify-end'>
              <LinkButton onClick={() => setShowDetail(v => !v)} text={showDetail ? '閉じる' : '開く'} />
        </div>
        {
          showDetail && (
            <p className='whitespace-pre-line'>
              {player.content}
            </p>
          )
        }
      </VerticalTimelineElement>
      {
        steps.map((step, idx) => (
          <CommandFlowItem key={idx} player={player} step={step} />
        ))
      }
      <VerticalTimelineElement
        iconStyle={{ background: '#fff', color: '#000' }}
        icon={<CommandIcon />}
      />
    </VerticalTimeline>
  );
};

interface CommandFlowItemProps {
  player: PlayerDetailDto;
  step: CommandStep | CommandStepBase;
}

const CommandFlowItem: React.FC<CommandFlowItemProps> = ({ player, step }) => {
  const navigate = useNavigate();

  const createdAt = 'createdAt' in step ? dayjs(step.createdAt).format('YYYY/M/D H:mm') : '';
  const [showDetail, setShowDetail] = useState(false);
  const { execFetch: fetchCommand, data: command } = useFetch<CommandDto>();
  const { execFetch: fetchParentCommand, data: parentCommand } = useFetch<CommandDto>();
  const { execFetch: fetchCommandResult, data: commandResult } = useFetch<CommandResult>();

  useEffect(() => {
    if (step.tableName === 'commands') {
      fetchCommand(ENDPOINT.COMMAND(player.teamId, step.primaryId));
    }
    if (step.tableName === 'command_results') {
      fetchCommandResult(ENDPOINT.COMMAND_RESULT(player.teamId, step.primaryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (command) {
      fetchParentCommand(ENDPOINT.COMMAND(player.teamId, command.parentId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command]);

  if (command && parentCommand) {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName='text-gray-500'
        date={createdAt}
        iconStyle={{ background: '#fff', color: '#000' }}
        icon={<CommandIcon />}
      >
        <p onClick={() => {
          navigate(`/teams/${player.teamId}/players/${player.id}/commands/${command.id}`);
        }} className='cursor-pointer absolute -top-4 -left-2 px-1 bg-white text-blue-500 rounded-full transition-transform transform-gpu'>v{command.versionNo}</p>
        <div className='flex justify-between items-center space-x-2'>
          <h2 className='font-semibold'>{parentCommand.summary}</h2>
        </div>
        <div className='text-sm flex justify-end'>
          <LinkButton onClick={() => setShowDetail(v => !v)} text={showDetail ? '閉じる' : '開く'} />
        </div>
        {
          showDetail && (
            <p className='whitespace-pre-line'>
              {command.content}
            </p>
          )
        }
      </VerticalTimelineElement>
    );
  }

  if (commandResult) {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName='text-gray-500'
        iconStyle={{ background: '#fff', color: '#000' }}
        date={createdAt}
        icon={<CommandResultIcon />}
      >
        <p onClick={() => {
          navigate(`/teams/${player.teamId}/players/${player.id}/commands/${commandResult.commandId}/results/${commandResult.id}`);
        }} className='cursor-pointer absolute -top-4 -left-2 px-1 bg-white text-blue-500 rounded-full transition-transform transform-gpu'>v{commandResult.versionNo}</p>
        {
          showDetail ? (
            <p className='whitespace-pre-line'>
              {commandResult.content}
            </p>
          ) : (
            <p className='whitespace-pre-line'>
                {commandResult.content.slice(0, 50)}
                {commandResult.content.length > 50 ? '...' : ''}
            </p>
          )
        }
        <div className='text-sm flex justify-end'>
          <LinkButton onClick={() => setShowDetail(v => !v)} text={showDetail ? '閉じる' : '開く'} />
        </div>
      </VerticalTimelineElement>
    );
  }

  return null;
};