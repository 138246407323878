import React from 'react';

interface SecondaryButtonProps {
  text: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({ text, onClick, loading = false, disabled = false, fullWidth = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      className={`border-2 font-bold rounded-full text-gray-900 bg-white px-8 py-4 transition 
        ${loading || disabled ? 'border-gray-400 bg-gray-200 cursor-not-allowed' : 'border-gray-900 hover:bg-gray-200'}
        ${fullWidth ? 'w-full' : ''}
        `}
    >
      {loading ? '送信中...' : text}
    </button>
  );
};
