import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { toast } from 'react-toastify';
import { CommandResult } from '../../../dtos';
import { useFetch } from '../../../utils/api';
import { ENDPOINT } from '../../../const';
import { Loading } from '../../atoms/Loading';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import { CommandResultEditForm } from '../../organisms/CommandResultEditForm';

export const CommandResultEdit: React.FC = () => {
  const { teamId, playerId, commandId, commandResultId } = useParams();

  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
    { label: '詳細', to: `/teams/${teamId}/players/${playerId}` },
    { label: 'リクエスト', to: `/teams/${teamId}/players/${playerId}/commands/${commandId}` },
    { label: '結果変更', to: `/teams/${teamId}/players/${playerId}/commands/${commandId}/results/${commandResultId}/edit` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchData, data, loading } = useFetch<CommandResult>();
  useEffect(() => {
    fetchData(ENDPOINT.COMMAND_RESULT(teamId as string, commandResultId as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !data) return <Loading />;
  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="結果を変更する">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <CommandResultEditForm targetCommandResult={data} onComplete={(data: CommandResult) => {
        toast.success('結果を変更しました。');
        navigate(`/teams/${teamId}/players/${playerId}/commands/${data.commandId}/results/${data.id}`);
      }} />
    </div>
  );
};
