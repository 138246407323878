import React from 'react';
import { FcAcceptDatabase } from "react-icons/fc";

interface CommandResultIconProps { }

export const CommandResultIcon: React.FC<CommandResultIconProps> = () => {
  return (
    <FcAcceptDatabase className='size-6' />
  );
};
