import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlayerDetailDto } from '../../dtos';
import { useUpdate } from '../../utils/api';
import { ENDPOINT } from '../../const';
import { TextInput } from '../atoms/TextInput';
import { Textarea } from '../atoms/Textarea';
import { PrimaryButton } from '../atoms/PrimaryButton';
import FormField from '../molecules/FormField';

interface Props {
  onComplete: (player: PlayerDetailDto) => void;
}

export const PlayerCreateForm: React.FC<Props> = ({ onComplete }) => {
  const { teamId } = useParams();
  const [name, setName] = useState('');
  const [detail, setDetail] = useState('');
  const { execUpdate: createPlayer, submitting } = useUpdate<{ name: string, content: string, parentId?: string }, PlayerDetailDto>({
    path: ENDPOINT.PLAYERS(teamId as string),
    method: 'POST',
    onComplete: async (data: PlayerDetailDto) => {
      onComplete(data);
    },
  });

  return (
    <form className="space-y-4">
      <p className="mb-8 text-base text-gray-500">あなた色の専門家「プレイヤー」を、AIに依頼して作成しましょう。役割を与え、チューニングをすることで、永遠に進化を続けます。</p>
      <FormField id="name" label="どんな専門家？" required>
        <TextInput placeholder="例）人生の楽しみを追求し尽くした達人" value={name} onChange={setName} />
      </FormField>
      <FormField id="name" label="知っていて欲しいこと" required>
        <Textarea placeholder={"例）私は1991年生まれの男性です。東京生まれで小中高は公立に通い、大学に進学しました。\n株式会社リクルートに入社し、Webサービスの企画・開発を経て、先輩が立ち上げた会社に創業初期に参加し、0からサービスを企画して売り上げを立てていく経験を詰んでいます。\n自分の会社を起業し、仲間と一緒に楽しんで、モノの見方も一緒に上がっていけるような事業を毎日一緒にいて考える日々を過ごしています。\n商品開発、EC事業、Webサービスの企画・開発・運用をメインの事業としています。"} rows={15} errorMessages={detail.length > 0 ? [] : ['入力してください']} value={detail} onChange={setDetail} />
      </FormField>
      <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
      <PrimaryButton loading={submitting} disabled={submitting || name.length === 0 || detail.length === 0} fullWidth text={'AIに内容を最適化させて作成する'} onClick={async () => {
        await createPlayer({ name, content: `あなたは${name}の専門家です。\n\n${detail}` });
      }} />
      </div>
    </form>
  );
};
