import React, { useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { PagingDto, PlayerListItemDto } from '../../dtos';
import { TeamMembersContext } from '../templates/AuthTeamLayout';
import { CommandCount } from '../atoms/CommandCount';

interface PlayerItemListProps {
  data: PagingDto<PlayerListItemDto>;
  onItemClick: (item: PlayerListItemDto) => void;
}

const PlayerItemList: React.FC<PlayerItemListProps> = ({ data, onItemClick }) => {
  const teamMembers = useContext(TeamMembersContext);
  return (
    <ul className="divide-y divide-gray-300">
      {data.items.map((item) => (
        <li
          key={item.id}
          className="py-4 px-2 hover:bg-gray-100 cursor-pointer transition"
          onClick={() => onItemClick(item)}
        >
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <div>
              <p className="text-black font-bold">{item.summary}</p>
              <CommandCount count={item.commandCount} />
            </div>
            <div className="flex flex-col items-end">
              <p className="text-gray-500 text-xs">{dayjs(item.createdAt).format('YYYY/M/D H:mm')}</p>
              <p className="flex space-x-1">
                <span className="text-gray-500 text-xs">作成者:</span>
                <span className="text-gray-500 text-xs">{teamMembers?.find((member) => member.id === item.createdBy)?.name}</span>
              </p>
              <p className="flex space-x-1">
                <span className="text-gray-500 text-xs">担当者:</span>
                {
                  teamMembers?.find((member) => member.id === item.assigneeId) ? (
                    <span className='text-gray-500 text-xs'>{teamMembers?.find((member) => member.id === item.assigneeId)?.name}</span>
                  ) : (
                    <span className='text-gray-500 text-xs'>(未設定)</span>
                  )
                }
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PlayerItemList;