import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { PagingDto, PlayerListItemDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import { Loading } from '../../atoms/Loading';
import { useFetch } from '../../../utils/api';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import PlayerItemList from '../../molecules/PlayerItemList';
import Paging from '../../molecules/Paging';

const defaultPage = 1;
const defaultPageSize = 50;

export const PlayerList: React.FC = () => {
  const { teamId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchData, data, loading } = useFetch<PagingDto<PlayerListItemDto>>();

  useEffect(() => {
    let page = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
    page = isNaN(page) ? defaultPage : page;
    let pageSize = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
    pageSize = isNaN(pageSize) ? defaultPageSize : pageSize;
    fetchData(`${ENDPOINT.PARENT_PLAYERS(teamId as string)}?page=${page}&pageSize=${pageSize}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (loading) return <Loading />;

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="プレイヤー一覧">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text="プレイヤーを作る" onClick={() => navigate(`/teams/${teamId}/players/new`)} />
      </div>
      <div>
        {
          !data ? (
            <Loading />
          ) : data.items.length > 0 ? (
            <>
              <Paging
                data={data}
                onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                defaultPage={defaultPage}
                defaultPageSize={defaultPageSize}
              />

              <PlayerItemList
                  data={data}
                onItemClick={(item) => {
                  navigate(`/teams/${teamId}/players/${item.id}#latest`);
              }}
            />
                <Paging
                  data={data}
                  onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                />
              </>
          ) : (null)
        }
      </div>
    </div>
  );
};
