import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { CommandListItemDto, CommandNewDto, CommentDto, PagingDto, PlayerDetailDto } from '../../../dtos';
import { ENDPOINT, STORAGE } from '../../../const';
import { toast } from 'react-toastify';
import { LinkButton } from '../../atoms/LinkButton';
import { SelectInput } from '../../atoms/SelectInput';
import dayjs from 'dayjs';
import { Loading } from '../../atoms/Loading';
import EditButton from '../../atoms/EditButton';
import { TextInput } from '../../atoms/TextInput';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { Modal } from '../../organisms/Modal';
import { useFetch, useUpdate } from '../../../utils/api';
import { SubTitle } from '../../atoms/SubTitle';
import CommandItemList from '../../molecules/CommandItemList';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import { CommandIcon } from '../../atoms/CommandIcon';
import { TeamMembersContext } from '../../templates/AuthTeamLayout';
import CommentItemList from '../../molecules/CommentItemList';
import { SecondaryButton } from '../../atoms/SecondaryButton';
import { Textarea } from '../../atoms/Textarea';
import Paging from '../../molecules/Paging';
import { CommentSectionTitle } from '../../molecules/CommentSectionTitle';
import { CommentCount } from '../../atoms/CommentCount';

const defaultPage = 1;
const defaultPageSize = 5;

export const PlayerDetail: React.FC = () => {
  const { teamId, playerId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();
  const teamMembers = useContext(TeamMembersContext);

  const hasLatestHash = location.hash === '#latest';

  const [showFullContent, setShowFullContent] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [summary, setSummary] = useState('');
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [assigneeId, setAssigneeId] = useState('');
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const { execFetch: fetchPlayer, data: playerDetail } = useFetch<PlayerDetailDto>();
  const { execFetch: fetchPlayersByParentId, data: players } = useFetch<PlayerDetailDto[]>();
  const { execFetch: fetchParentCommands, data: pagingCommandListData } = useFetch<PagingDto<CommandListItemDto>>();
  const { execFetch: fetchComments, data: comments } = useFetch<CommentDto[]>();
  const { execUpdate: updateSummary, submitting } = useUpdate<{ summary: string }, PlayerDetailDto>({
    method: 'PUT',
    onComplete: async () => {
      toast.success('要約を変更しました。');
      setSummary('');
      await fetchPlayersByParentId(ENDPOINT.PARENT_PLAYER(teamId as string, playerDetail?.parentId as string));
    },
  });
  const { execUpdate: assign, submitting: assigning } = useUpdate<{ assigneeId: string | null }, PlayerDetailDto>({
    method: 'PUT',
    onComplete: async () => {
      toast.success('担当者を変更しました。');
      setSummary('');
      await fetchPlayersByParentId(ENDPOINT.PARENT_PLAYER(teamId as string, playerDetail?.parentId as string));
    },
  });
  const { execUpdate: createComment, submitting: commenting } = useUpdate<{ content: string, tableName: string, primaryId: string }, PlayerDetailDto>({
    method: 'POST',
    onComplete: async () => {
      toast.success('コメントしました。');
      setComment('');
      await fetchComments(ENDPOINT.TEAM_COMMENTS(teamId as string, 'players', playerDetail?.id as string));
    },
  });

  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
    { label: '詳細', to: `/teams/${teamId}/players/${playerId}` },
  ];

  useEffect(() => {
    if (teamId && playerDetail) {
      fetchPlayersByParentId(ENDPOINT.PARENT_PLAYER(teamId as string, playerDetail?.parentId as string));
      fetchComments(ENDPOINT.TEAM_COMMENTS(teamId as string, 'players', playerDetail.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, playerDetail]);

  useEffect(() => {
    if (playerDetail) {
      let page = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
      page = isNaN(page) ? defaultPage : page;
      let pageSize = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
      pageSize = isNaN(pageSize) ? defaultPageSize : pageSize;
      fetchParentCommands(`${ENDPOINT.PARENT_COMMANDS(teamId as string)}?playerId=${playerDetail.id}&page=${page}&pageSize=${pageSize}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, playerDetail]);

  useEffect(() => {
    if (playerId) {
      fetchPlayer(ENDPOINT.PLAYER(teamId as string, playerId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, playerId]);

  useEffect(() => {
    if (players) {
      setSummary(players.find(d => d.id === d.parentId)?.summary ?? '');
      if (hasLatestHash) {
        navigate(`/teams/${teamId}/players/${players[0].id}`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players, hasLatestHash]);

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const parentPlayer = useMemo(() => {
    return players?.find(d => d.id === playerDetail?.parentId);
  }, [players, playerDetail]);

  useEffect(() => {
    if (parentPlayer) {
      setAssigneeId(parentPlayer.assigneeId ?? '');
    }
  }, [parentPlayer]);

  if (hasLatestHash || !players || !playerDetail || !parentPlayer || !pagingCommandListData || !comments) {
    return <Loading />;
  }

  return (
    <div className='space-y-8'>
      <div>
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <div className='flex items-center space-x-2'>
            <Title title={parentPlayer.summary ?? ""}>
              <PlayerIcon />
            </Title>
            <EditButton onClick={() => setIsSummaryModalOpen(true)} />
          </div>
          <div className='flex justify-between items-center'>
            <SelectInput
              options={players.map((item) => ({
                label: `v${item.versionNo} - ${dayjs(item.createdAt).format('YYYY/M/D H:mm')}`,
                value: item.id,
              }))}
              value={playerDetail.id}
              onChange={(v) => {
                navigate(`/teams/${teamId}/players/${v}`);
              }}
            />
            <div className="flex flex-col items-end">
              <p className="flex space-x-1">
                <span className="text-gray-500 text-xs">作成者:</span>
                <span className="text-gray-500 text-xs">{teamMembers?.find((member) => member.id === parentPlayer.createdBy)?.name}</span>
              </p>
              <p className="flex space-x-1">
                <span className="text-gray-500 text-xs">担当者:</span>
                <span onClick={() => setIsAssignModalOpen(true)} className='cursor-pointer text-gray-500 text-xs underline'>{teamMembers?.find((member) => member.id === parentPlayer.assigneeId)?.name ?? '(未設定)'}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='mt-4 flex justify-end'>
          <CommentCount count={comments.length} clickable />
        </div>
      </div>


      <div className='space-y-4'>
        <div className='flex items-center space-x-2'>
          <SubTitle title="どんな専門家？" />
          <EditButton onClick={() => navigate(`/teams/${teamId}/players/${playerId}/edit${location.search}`)} />
        </div>
        <div>
          <div className='relative'>
            <p className='font-bold text-2xl text-gray-500'>「</p>
            <div className='px-10'>
              <div className={`whitespace-pre-line`}>
                {
                  showFullContent ? playerDetail.content : `${playerDetail.content.slice(0, 100)}${playerDetail.content.length > 100 ? '...' : ''}`
                }
              </div>
              {/* すべて見るボタン */}
              {playerDetail.content.length > 100 && (
                <div className="mt-2">
                  <LinkButton
                    onClick={() => toggleShowFullContent()}
                    text={showFullContent ? '折りたたむ' : 'すべて見る'}
                  />
                </div>
              )}
            </div>
            <p className='flex justify-end font-bold text-2xl text-gray-500'>」</p>
          </div>
        </div>
      </div>

      <div className='space-y-4'>
        <div className='flex items-center space-x-2'>
          <SubTitle title="リクエスト">
            <CommandIcon />
          </SubTitle>
        </div>
        <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-4 sm:w-auto w-full'>
          <div className='flex items-center justify-end space-x-2 max-w-screen-sm mx-auto'>
            <div className="flex-shrink-0 w-auto sm:text-base text-sm">
              <SecondaryButton
                fullWidth
                text="コメントする"
                onClick={() => setIsCommentModalOpen(true)}
              />
            </div>
            <div className="flex-shrink-0 w-auto sm:text-base text-sm">
              <PrimaryButton
                fullWidth
                text="新しくリクエストする"
                onClick={() => {
                  const commandNew: CommandNewDto = {
                    baseSteps: [],
                  }
                  localStorage.setItem(STORAGE.COMMAND_NEW_DATA, JSON.stringify(commandNew));
                  navigate(`/teams/${teamId}/players/${playerId}/commands/new`);
                }}
              />
            </div>
          </div>
        </div>
        {
          pagingCommandListData.items.length > 0 ? (
            <>
              <Paging
                data={pagingCommandListData}
                onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                defaultPage={defaultPage}
                defaultPageSize={defaultPageSize}
              />
              <CommandItemList
              data={pagingCommandListData}
              onItemClick={(item) => {
                navigate(`/teams/${teamId}/players/${playerDetail.id}/commands/${item.id}#latest`);
              }}
              />
              <Paging
                data={pagingCommandListData}
                onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                defaultPage={defaultPage}
                defaultPageSize={defaultPageSize}
              />
            </>
          ) : (null)
        }
      </div>

      <div className='space-y-4'>
        <div className='flex items-center space-x-2'>
          <CommentSectionTitle />
        </div>
        {
          comments.length > 0 ? (
            <CommentItemList
              items={comments}
            />
          ) : (
            <p className='text-gray-500'>コメントはありません。</p>
          )
        }
      </div>

      <Modal isOpen={isSummaryModalOpen} onClose={() => setIsSummaryModalOpen(false)} title="要約を変更する">
        <form className="space-y-4">
          <TextInput value={summary} onChange={(value) => setSummary(value)} placeholder="要約" />
          <PrimaryButton
            disabled={summary.length === 0 || submitting}
            loading={submitting}
            fullWidth
            text="要約を変更する"
            onClick={async () => {
              await updateSummary({ summary }, ENDPOINT.PLAYER(teamId as string, playerDetail.parentId as string));
              setIsSummaryModalOpen(false);
            }}
          />
        </form>
      </Modal>

      <Modal isOpen={isAssignModalOpen} onClose={() => setIsAssignModalOpen(false)} title="担当者を変更する">
        <form className="space-y-4">
          {
            teamMembers && (
              <SelectInput options={[{ value: '', label: '(未設定)' }, ...teamMembers.map((member) => ({ label: member.name, value: member.id }))]} value={assigneeId} onChange={(value) => setAssigneeId(value)} />
            )
          }
          <PrimaryButton
            disabled={assigning}
            loading={assigning}
            fullWidth
            text="担当者を変更する"
            onClick={async () => {
              await assign({ assigneeId: assigneeId === '' ? null : assigneeId }, ENDPOINT.PLAYER_ASSIGN(teamId as string, playerDetail.parentId as string));
              setIsAssignModalOpen(false);
            }}
          />
        </form>
      </Modal>

      <Modal isOpen={isCommentModalOpen} onClose={() => setIsCommentModalOpen(false)} title="コメントする">
        <form className="space-y-4">
          <Textarea value={comment} onChange={(value) => setComment(value)} />
          <PrimaryButton
            disabled={comment.length === 0 || commenting}
            loading={commenting}
            fullWidth
            text="コメントする"
            onClick={async () => {
              await createComment({ content: comment, tableName: 'players', primaryId: playerDetail.id }, ENDPOINT.TEAM_COMMENTS(teamId as string, 'players', playerDetail.id));
              setIsCommentModalOpen(false);
            }}
          />
        </form>
      </Modal>
    </div>
  );
};