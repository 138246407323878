import { toast } from "react-toastify";
import { useCallback, useState } from 'react';

export interface ErrorResponse {
  code: string;
  msg: string;
};

const isErrorResponse = (data: any): data is ErrorResponse => {
  return typeof data === 'object' && typeof data.code === 'string' && typeof data.msg === 'string';
};

export const handleApiResponse = async (response: Response, onFailure?: (e: any) => void): Promise<boolean> => {
  if (!response.ok) {
    const responseData = await response.json();
    if (onFailure) {
      onFailure(responseData);
    } else {
      // 型ガードを使って ErrorResponse 型であるかを確認
      if (isErrorResponse(responseData)) {
        toast.error(responseData.msg); // エラーメッセージを表示
      } else {
        toast.error('エラーが発生しました。しばらくしてから再度お試しください。'); // 汎用エラーメッセージ
      }
    }
    return false;
  }
  return true;
};

type FetchArg = {
  path?: string;
  onFailure?: (e: any) => void;
};

type FetchReturnValue<T> = {
  data?: T | null;
  execFetch: (path?: string) => Promise<void>;
  loading: boolean;
};

type UpdateArg<P> = {
  method: 'POST' | 'PUT' | 'DELETE';
  path?: string;
  onComplete?: (response: P) => void;
  onFailure?: (e: any) => void;
};

type UpdateReturnValue<Q> = {
  execUpdate: (request: Q, path?: string) => Promise<void>;
  submitting: boolean;
};

export const useFetch = <T>(arg?: FetchArg): FetchReturnValue<T> => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const execFetch = useCallback(
    async (path?: string) => {
      if (!arg?.path && !path) {
        throw new Error('path is required');
      }
      setLoading(true);
      const targetPath = path || arg?.path;
      const response = await fetch(targetPath as string, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const isResponseOk = await handleApiResponse(response, arg?.onFailure);
      setLoading(false);
      if (!isResponseOk) return;
      const data = await response.json();
      setData(data);
      // eslint-disable-next-line
    },
    [setData, setLoading, arg],
  );
  return {
    data,
    loading,
    execFetch,
  };
};

export const useUpdate = <Q, P>(arg: UpdateArg<P>): UpdateReturnValue<Q> => {
  const [submitting, setSubmitting] = useState(false);
  const execUpdate = useCallback(
    async (request: Q, path?: string) => {
      if (!arg?.path && !path) {
        throw new Error('path is required');
      }
      setSubmitting(true);
      const targetPath = path || arg.path;
      const response = await fetch(targetPath as string, {
        method: arg.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      const isResponseOk = await handleApiResponse(response, arg?.onFailure);
      setSubmitting(false);
      if (!isResponseOk) return;
      const data = await response.json();
      if (arg.onComplete) {
        arg.onComplete(data);
      }
      // eslint-disable-next-line
    },
    [setSubmitting, arg],
  );
  return {
    submitting,
    execUpdate,
  };
};
