import React from 'react';
interface TitleProps {
  title: string;
  children: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({ title, children }) => {
  return (
    <div className="flex items-center space-x-1">
      {children}
      <h1
        className=" text-xl md:text-2xl font-bold"
      >
        {title}
      </h1>
    </div>
  );
};
