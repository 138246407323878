import React from 'react';
import { FaRegEdit } from "react-icons/fa";
// ボタンのプロパティ型定義
interface EditButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const EditButton: React.FC<EditButtonProps> = ({ onClick, disabled }) => {
  return (
    <button
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
      disabled={disabled}
      className={`p-1 text-lg bg-white text-blue-500 font-bold rounded-full transition-transform transform-gpu ${disabled ? 'bg-gray-400 cursor-not-allowed' : ''}`}
    >
      <FaRegEdit size={20} />
    </button>
  );
};

export default EditButton;