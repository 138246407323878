import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { TeamDto, TeamCreateRequest } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import ItemList from '../../molecules/ItemList';
import { useFetch, useUpdate } from '../../../utils/api';
import { Loading } from '../../atoms/Loading';
import { Modal } from '../../organisms/Modal';
import { TextInput } from '../../atoms/TextInput';
import { toast } from 'react-toastify';
import { TeamIcon } from '../../atoms/TeamIcon';

export const TeamList: React.FC = () => {
  const breadcrumbItems = [
    { label: 'チーム一覧', to: `/admin/teams` },
  ];
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');

  const { execFetch: fetchData, data: teams, loading } = useFetch<TeamDto[]>({
    path: ENDPOINT.TEAMS,
  });

  const { execUpdate: createTeam, submitting } = useUpdate<TeamCreateRequest, TeamDto>({
    path: ENDPOINT.TEAMS,
    method: 'POST',
    onComplete: async () => {
      toast.success('チームを作成しました。');
      setName('');
      await fetchData();
    },
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="チーム一覧">
            <TeamIcon />
          </Title>
        </div>
      </div>
      <div className="mt-6">
        <PrimaryButton fullWidth text="チームを作る" onClick={() => setIsModalOpen(true)} />
      </div>
      <div className='mt-4'>
        {
          !teams ? (
            <Loading />
          ) : teams.length > 0 ? (
            <ItemList
              items={teams.map((team) => ({ id: team.id, label: team.name, createdAt: team.createdAt }))}
              onItemClick={(id) => {
                navigate(`/admin/teams/${id}`);
              }}
            />
          ) : (null)
        }
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="チームを作る">
        <form className="space-y-4">
          <TextInput value={name} onChange={(value) => setName(value)} placeholder="チーム名" />
          <PrimaryButton disabled={name.length === 0 || submitting} loading={submitting} fullWidth text="チームを作る" onClick={async () => {
            await createTeam({ name });
            setIsModalOpen(false);
          }} />
        </form>
      </Modal>
    </div>
  );
};
