import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CommandDto, CommandNewDto, CommandStepBase, PlayerDetailDto } from '../../dtos';
import { useFetch, useUpdate } from '../../utils/api';
import { ENDPOINT, STORAGE } from '../../const';
import { Textarea } from '../atoms/Textarea';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { toast } from 'react-toastify';
import { CommandFlow } from './CommandFlow';

interface Props {
  targetCommand?: CommandDto;
  onComplete: (player: CommandDto) => void;
}

export const CommandCreateForm: React.FC<Props> = ({ targetCommand, onComplete }) => {
  const { teamId, playerId } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [commandNewData, setCommandNewData] = useState<CommandNewDto | null>(null);

  const { execFetch: fetchPlayer, data: player } = useFetch<PlayerDetailDto>();
  const { execUpdate: createCommand, submitting } = useUpdate<{ content: string, baseSteps: CommandStepBase[], parentId?: string }, CommandDto>({
    method: 'POST',
    onComplete: async (data: CommandDto) => {
      onComplete(data);
    },
  });

  useEffect(() => {
    if (targetCommand) {
      setContent(targetCommand.content);
    }
  }, [targetCommand]);

  useEffect(() => {
    if (teamId && playerId) {
      fetchPlayer(ENDPOINT.PLAYER(teamId, playerId));
    }
    const commandNewDataJson = localStorage.getItem(STORAGE.COMMAND_NEW_DATA);
    if (commandNewDataJson) {
      const data = JSON.parse(commandNewDataJson);
      setCommandNewData(data);
      return;
    }
    toast.error('遷移が不正です。');
    navigate(`/teams/${teamId}/players/${playerId}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, teamId, playerId, setCommandNewData]);

  return (
    <>
      <form className="space-y-4">
        <Textarea placeholder={"何をリクエストする？\n"} rows={10} errorMessages={content.length > 0 ? [] : ['入力してください']} value={content} onChange={setContent} />
        {
          commandNewData && (
            <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
              <PrimaryButton loading={submitting} disabled={submitting || content.length === 0} fullWidth text={targetCommand ? '内容を変更してリクエストする' : '新しくリクエストする'} onClick={async () => {
                await createCommand({ content, baseSteps: commandNewData.baseSteps, parentId: targetCommand?.parentId ?? undefined }, ENDPOINT.PLAYER_COMMANDS(teamId as string, playerId as string));
                sessionStorage.removeItem(STORAGE.COMMAND_NEW_DATA);
              }} />
            </div>
          )
        }
      </form>
      {
        player && commandNewData && (
          <CommandFlow player={player} steps={commandNewData.baseSteps} />
        )
      }
    </>
  );
};
