import React from 'react';

interface PrimaryButtonProps {
  text: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  onClick,
  loading = false,
  disabled = false,
  fullWidth = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      className={`
        px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu
        bg-blue-700 text-white px-6 py-2 transition
        ${loading || disabled
        ? 'bg-gradient-to-r from-blue-300 to-purple-300 cursor-not-allowed'
        : 'hover:-translate-y-1 hover:shadow-lg'
        } 
        ${fullWidth ? 'w-full' : ''}
      `}
    >
      {loading ? '送信中...' : text}
    </button>
  );
};