import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ENDPOINT } from '../../../const';
import { useFetch, useUpdate } from '../../../utils/api';
import { Loading } from '../../atoms/Loading';
import { SystemPlayerDto } from '../../../dtos';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { Title } from '../../atoms/Title';
import { Textarea } from '../../atoms/Textarea';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { toast } from 'react-toastify';
import { PlayerIcon } from '../../atoms/PlayerIcon';

export const SystemPlayerDetail: React.FC = () => {
  const { code } = useParams();

  const breadcrumbItems = [
    { label: 'システムプレイヤー', to: '/admin/system-players' },
    { label: '変更', to: `/admin/system-players/${code}` },
  ];

  const [content, setContent] = useState('');

  const { execFetch: fetchData, data: systemPlayers, loading } = useFetch<SystemPlayerDto[]>();

  const { execUpdate: createSystemPlayer, submitting } = useUpdate<{ content: string }, SystemPlayerDto>({
    method: 'POST',
    onComplete: async () => {
      toast.success('システムプレイヤーを変更しました。');
      await fetchData(ENDPOINT.SYSTEM_PLAYER(code as string));
    },
  });

  useEffect(() => {
    if (code) {
      fetchData(ENDPOINT.SYSTEM_PLAYER(code as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (systemPlayers && systemPlayers.length > 0) {
      setContent(systemPlayers[0].content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemPlayers, setContent]);

  if (loading || !systemPlayers) {
    return (
      <Loading />
    )
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="システムプレイヤーの変更">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <form className="mt-6 flex flex-col gap-4">
        <p>コード: {code}</p>
        <Textarea rows={20} errorMessages={content.length > 0 ? [] : ['内容を入力してください']} value={content} onChange={setContent} />
        <PrimaryButton loading={submitting} disabled={content.length === 0 || submitting} fullWidth text="変更する" onClick={async () => {
          await createSystemPlayer({
            content: content,
          }, ENDPOINT.SYSTEM_PLAYER(code as string));
        }} />
      </form>
    </div>
  );

};
