import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from './AuthLayout';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { Outlet } from 'react-router-dom';
import MenuButton from '../atoms/MenuButton';
import { Modal } from '../organisms/Modal';
import { SecondaryButton } from '../atoms/SecondaryButton';
import { UserDto } from '../../dtos';
import { useFetch } from '../../utils/api';
import { ENDPOINT } from '../../const';
import CopyButton from '../atoms/CopyButton';
import { logout } from '../../firebase';

export const TeamMembersContext = createContext<UserDto[] | null>(null);

export const AuthTeamLayout: React.FC = () => {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [teamMembers, setTeamMembers] = useState<UserDto[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { execFetch: fetchData, data } = useFetch<UserDto[]>();

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (teamId) {
      fetchData(ENDPOINT.TEAM_USERS(teamId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    if (data) {
      setTeamMembers(data);
    }
  }, [data]);

  if (!auth?.teams?.some(team => team.id === teamId)) {
    return (
      <div>
        <p className='pb-2 text-xl text-center'>アクセスする権限がありません。</p>
        <PrimaryButton fullWidth onClick={() => navigate('/teams')} text='所属チームを確認する' />
      </div>
    );
  }
  return (
    <div className='px-2 py-8'>
      <div className="z-50 fixed bottom-20 right-2 sm:right-4">
        <div className='flex flex-col space-y-2'>
          <MenuButton onClick={() => setIsMenuOpen(true)} />
          <CopyButton />
        </div>
      </div>
      <Modal isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} title={auth.teams.find(team => team.id === teamId)?.name}>
        <div className='flex flex-col space-y-2'>
          <SecondaryButton text='プレイヤー一覧' onClick={() => {
            navigate(`/teams/${teamId}/players`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='リクエスト履歴' onClick={() => {
            navigate(`/teams/${teamId}/commands`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='チーム選択' onClick={() => {
            navigate(`/teams?id=${teamId}`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='ページ最新化' onClick={() => {
            window.location.reload();
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='ログアウト' onClick={async () => {
            await logout(() => {
              navigate('/login');
            });
            setIsMenuOpen(false);
          }} />
        </div>
      </Modal>
      <div className='mb-20'>
        <TeamMembersContext.Provider value={teamMembers}>
          <Outlet />
        </TeamMembersContext.Provider>
      </div>
    </div>
  );
};
