import React, { useMemo } from 'react';
import { STRING_TYPE, StringType } from '../../enums/string-type';
import { MarkdownRenderer } from './MarkdownRenderer';

interface ResultContentProps {
  content: string;
}

export const ResultContent: React.FC<ResultContentProps> = ({ content }) => {
  const stringType = useMemo(() => {
    return detectStringType(content);
  }, [content]);

  return stringType === STRING_TYPE.MARKDOWN ? (
    <div>
      <MarkdownRenderer markdown={content} />
    </div>
  ) : stringType === STRING_TYPE.JSON ? (
    <div className='whitespace-pre-line'>
      {JSON.stringify(content, null, 2)}
    </div>
  ) : (
    <div className='whitespace-pre-line'>
      {content}
    </div>
  );
};


const detectStringType = (input: string): StringType => {
  // JSONかどうかを判定
  try {
    JSON.parse(input);
    const isJson = typeof input === 'string' && input.trim().startsWith('{') && input.trim().endsWith('}');
    if (isJson) {
      return STRING_TYPE.JSON;
    }
  } catch (e) {
    // JSON.parse に失敗した場合は JSON ではない
  }

  return STRING_TYPE.MARKDOWN;

  // // マークダウン記法かどうかを判定
  // const markdownPattern = /^```(?:[a-z]*)\n[\s\S]*\n```$/;
  // const isMarkdown = markdownPattern.test(input.trim());

  // if (isMarkdown) {
  //   return STRING_TYPE.MARKDOWN;
  // }

  // return STRING_TYPE.MULTI_LINE;
}