import React, { useState } from 'react';

interface TextareaProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  errorMessages?: string[];
  rows?: number;
}

export const Textarea: React.FC<TextareaProps> = ({ value, onChange, placeholder, errorMessages = [], rows = 3 }) => {
  const [touched, setTouched] = useState(false);
  const hasError = errorMessages.length > 0 && touched;

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <div>
      <textarea
        value={value}
        onChange={e => onChange(e.target.value)}
        onBlur={handleBlur} // Set touched when the user interacts with the field
        placeholder={placeholder}
        rows={rows}
        className={`w-full p-2 border ${hasError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none ring-2 focus:ring-3 
          ${hasError ? 'focus:ring-red-500' : 'focus:ring-gray-900'}`}
      />
      {hasError && (
        <ul className="mt-1 text-sm text-red-500">
          {errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
