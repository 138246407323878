import React from 'react';
import { marked } from 'marked';

interface MarkdownRendererProps {
  markdown: string;
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ markdown }) => {
  const htmlContent = marked(markdown);
  return (
    <div
      className="prose prose-lg w-full max-w-none mx-auto"
      dangerouslySetInnerHTML={{ __html: htmlContent as string }}
    />
  );
};
