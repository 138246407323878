import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlayerDetailDto } from '../../dtos';
import { useUpdate } from '../../utils/api';
import { ENDPOINT } from '../../const';
import { Textarea } from '../atoms/Textarea';
import { PrimaryButton } from '../atoms/PrimaryButton';

interface Props {
  targetPlayer: PlayerDetailDto;
  onComplete: (player: PlayerDetailDto) => void;
}

export const PlayerEditForm: React.FC<Props> = ({ targetPlayer, onComplete }) => {
  const { teamId } = useParams();
  const [content, setContent] = useState('');
  const { execUpdate: createPlayer, submitting } = useUpdate<{ content: string, parentId?: string }, PlayerDetailDto>({
    path: ENDPOINT.PLAYERS(teamId as string),
    method: 'POST',
    onComplete: async (data: PlayerDetailDto) => {
      onComplete(data);
    },
  });

  useEffect(() => {
    if (targetPlayer) {
      setContent(targetPlayer.content);
    }
  }, [targetPlayer]);

  return (
    <form className="space-y-4">
      <Textarea placeholder={"どんな専門家？\n"} rows={20} errorMessages={content.length > 0 ? [] : ['入力してください']} value={content} onChange={setContent} />
      <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton loading={submitting} disabled={submitting || content.length === 0} fullWidth text={'内容を変更する'} onClick={async () => {
          await createPlayer({ content, parentId: targetPlayer.parentId });
        }} />
      </div>
    </form>
  );
};
