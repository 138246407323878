import React from 'react';
import { Link } from 'react-router-dom';

const Landing: React.FC = () => {
  return (
    <div className="min-h-screen bg-background font-body">
      <header className="bg-primary text-white">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold font-heading">StoryForge</h1>
          <nav>
            <ul className="flex space-x-4">
              <li><a href="#service" className="hover:text-accent">サービス内容</a></li>
              <li><a href="#features" className="hover:text-accent">特徴</a></li>
              <li><a href="#pricing" className="hover:text-accent">料金プラン</a></li>
              <li><a href="#flow" className="hover:text-accent">利用の流れ</a></li>
              <li><a href="#faq" className="hover:text-accent">よくある質問</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <section className="bg-gradient-to-b from-primary to-secondary text-white py-20">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-5xl font-bold font-heading mb-6">あなたのアイデアを、24時間で漫画の第一話に</h2>
            <p className="text-xl mb-8">24時間で あなたの漫画アイデアが 形になる</p>
            <Link to="/start" className="bg-accent text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-90 transition-colors">
              今すぐ無料体験
            </Link>
          </div>
        </section>

        <section id="service" className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">サービス概要</h2>
            <p className="text-lg mb-6">
              StoryForgeは、あなたの漫画アイデアを驚くほど短時間で形にする革新的なサービスです。
              アイデアはあるけれど、具体化に悩んでいる方へ：
            </p>
            <ul className="list-disc list-inside mb-6">
              <li>魅力的なストーリーのあらすじ</li>
              <li>個性豊かなキャラクター設定</li>
              <li>第一話の詳細な内容</li>
            </ul>
            <p className="text-lg">をたった24時間でお届けします。あなたの創作活動が、ここから加速します。</p>
          </div>
        </section>

        <section id="features" className="bg-gray-100 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">特徴</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <FeatureCard
                title="スピーディーな制作"
                items={[
                  '依頼から24時間以内に完成',
                  '締切に追われる日々からの解放'
                ]}
              />
              <FeatureCard
                title="洗練されたストーリー展開"
                items={[
                  '最新の創作理論を活用',
                  '読者を引き付ける魅力的な展開'
                ]}
              />
              <FeatureCard
                title="多彩なジャンル対応"
                items={[
                  '少年・少女マンガから青年誌まで幅広くカバー',
                  'SF、ファンタジー、恋愛、スポーツなど、あらゆるジャンルに対応'
                ]}
              />
              <FeatureCard
                title="カスタマイズされた提案"
                items={[
                  'あなたの要望やビジョンを反映',
                  '独自性の高いアイデアを提供'
                ]}
              />
            </div>
          </div>
        </section>

        <section id="flow" className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">利用の流れ</h2>
            <ol className="list-decimal list-inside space-y-4">
              <li>LINEでかんたんログイン（初回は無料体験）</li>
              <li>ジャンルと漫画の概要を入力</li>
              <li>「原稿作成依頼」ボタンをクリック</li>
              <li>24時間以内に完成通知</li>
              <li>ログインして内容を確認</li>
              <li>気に入ったらサブスクリプション登録</li>
            </ol>
          </div>
        </section>

        <section id="pricing" className="bg-gray-100 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">料金プラン</h2>
            <p className="text-center mb-8">特別キャンペーン実施中！<br />初回無料体験後、24時間以内の登録で特別割引価格</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <PricingCard
                title="ベーシックプラン"
                price="6,980"
                originalPrice="7,980"
                features={[
                  '月3回のアイデア生成',
                  '基本的な設定オプション'
                ]}
              />
              <PricingCard
                title="プロプラン"
                price="13,980"
                originalPrice="15,980"
                features={[
                  '月8回のアイデア生成',
                  '高度な設定オプション一部利用可能'
                ]}
                highlighted={true}
              />
              <PricingCard
                title="マスタープラン"
                price="25,800"
                originalPrice="29,800"
                features={[
                  '月20回のアイデア生成',
                  'すべての高度な設定オプション利用可能'
                ]}
              />
            </div>
            <p className="text-center mt-8">すべてのプランに7日間の返金保証付き！</p>
          </div>
        </section>

        <section id="advanced-options" className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">高度な設定オプション（マスタープラン）</h2>
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 list-disc list-inside">
              <li>対象読者層設定</li>
              <li>物語の複雑度</li>
              <li>キャラクター詳細設定</li>
              <li>世界観の深さ</li>
              <li>ストーリー展開のペース</li>
              <li>テーマ・メッセージの複雑さ</li>
              <li>描画スタイルに合わせた設定</li>
              <li>シリーズ構成</li>
              <li>独自の魅力ポイント指定</li>
              <li>クリフハンガーの頻度と強さ</li>
            </ul>
          </div>
        </section>

        <section id="testimonials" className="bg-gray-100 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">ユーザーの声</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <TestimonialCard
                name="佐藤 翔太"
                role="漫画家"
                comment="アイデアが具体化する速さに驚きました。締切前の強い味方です！"
              />
              <TestimonialCard
                name="田中 美咲"
                role="イラストレーター"
                comment="キャラクター設定の奥深さが素晴らしい。すぐに描き始められました。"
              />
              <TestimonialCard
                name="山田 健太郎"
                role="Web漫画家"
                comment="毎回新鮮なアイデアが届くので、創作の幅が広がりました。"
              />
            </div>
          </div>
        </section>

        <section id="faq" className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">よくある質問</h2>
            <div className="space-y-6">
              <FAQItem
                question="生成されたアイデアの著作権は？"
                answer="すべてのアイデアの著作権はユーザーに帰属します。"
              />
              <FAQItem
                question="無料体験の制限は？"
                answer="初回1回のみ無料でご利用いただけます。"
              />
              <FAQItem
                question="クレジットカード以外の支払い方法は？"
                answer="現在はクレジットカードのみの対応となっています。"
              />
              <FAQItem
                question="途中解約は可能？"
                answer="はい、いつでも解約可能です。日割り計算での返金も行っています。"
              />
              <FAQItem
                question="アイデアの修正や再生成は可能ですか？"
                answer="はい、各プランで定められた回数内であれば、何度でも新しいアイデアを生成できます。"
              />
            </div>
          </div>
        </section>

        <section id="cta" className="bg-primary text-white py-16">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold font-heading mb-6">24時間であなたの漫画アイデアが形に</h2>
            <Link to="/start" className="bg-accent text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-90 transition-colors">
              今すぐ無料体験を始める
            </Link>
          </div>
        </section>

        <section id="security" className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold font-heading text-center mb-12">安心・安全のサービス利用</h2>
            <ul className="list-disc list-inside space-y-4">
              <li>SSL暗号化通信で個人情報を保護</li>
              <li>厳格なプライバシーポリシーを遵守</li>
              <li>24時間365日のカスタマーサポート</li>
            </ul>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-2xl font-bold font-heading mb-4">StoryForge</h3>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">会社情報</h4>
              {/* 会社情報のリンクなど */}
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">ポリシー</h4>
              <ul className="space-y-2">
                <li><a href="/privacy" className="hover:text-accent">プライバシーポリシー</a></li>
                <li><a href="/terms" className="hover:text-accent">利用規約</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">お問い合わせ</h4>
              {/* 問い合わせ情報 */}
            </div>
          </div>
          <div className="mt-8 text-center">
            <div className="flex justify-center space-x-4 mb-4">
              {/* SNSアイコン */}
            </div>
            <p>&copy; 2023 StoryForge. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard: React.FC<{ title: string; items: string[] }> = ({ title, items }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-xl font-semibold font-heading mb-4">{title}</h3>
    <ul className="list-disc list-inside">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const PricingCard: React.FC<{ title: string; price: string; originalPrice: string; features: string[]; highlighted?: boolean }> = ({ title, price, originalPrice, features, highlighted = false }) => (
  <div className={`bg-white p-6 rounded-lg shadow-md ${highlighted ? 'ring-2 ring-accent' : ''}`}>
    <h3 className="text-2xl font-semibold font-heading mb-2">{title}</h3>
    <p className="text-4xl font-bold mb-1">¥{price}<span className="text-sm font-normal">/月</span></p>
    <p className="text-sm mb-4">通常価格: ¥{originalPrice}/月</p>
    <ul className="space-y-2 mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center">
          <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <Link to="/start" className={`block w-full py-2 px-4 rounded ${highlighted ? 'bg-accent text-white' : 'bg-primary text-white'} hover:bg-opacity-90 transition-colors text-center`}>
      プランを選択
    </Link>
  </div>
);

const TestimonialCard: React.FC<{ name: string; role: string; comment: string }> = ({ name, role, comment }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <p className="mb-4">{comment}</p>
    <div className="flex items-center">
      <div className="w-12 h-12 bg-gray-300 rounded-full mr-4"></div>
      <div>
        <p className="font-semibold">{name}</p>
        <p className="text-sm text-gray-600">{role}</p>
      </div>
    </div>
  </div>
);

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => (
  <div className="border-b border-gray-200 pb-4">
    <h3 className="text-xl font-semibold font-heading mb-2">{question}</h3>
    <p>{answer}</p>
  </div>
);

export default Landing;