import React from 'react';

export const Loading: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen space-x-2">
      <div className="w-4 h-4 rounded-full bg-gray-900 animate-bounce"></div>
      <div className="w-4 h-4 rounded-full bg-gray-900 animate-bounce animation-delay-200"></div>
      <div className="w-4 h-4 rounded-full bg-gray-900 animate-bounce animation-delay-400"></div>
    </div>
  );
};
