import React from 'react';

interface SelectInputProps {
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
  errorMessages?: string[];
}

export const SelectInput: React.FC<SelectInputProps> = ({ options, value, onChange, errorMessages = [] }) => {
  const hasError = errorMessages.length > 0;

  return (
    <div>
      <select
        value={value}
        onChange={e => onChange(e.target.value)}
        className={`w-full p-2 border ${hasError ? 'border-red-500' : 'border-gray-300'} rounded-md bg-white focus:outline-none focus:ring-2 
          ${hasError ? 'focus:ring-red-500' : 'focus:ring-gray-500'}`}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {hasError && (
        <ul className="mt-1 text-sm text-red-500">
          {errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
