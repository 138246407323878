import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { toast } from 'react-toastify';
import { PlayerDetailDto } from '../../../dtos';
import { useFetch } from '../../../utils/api';
import { ENDPOINT } from '../../../const';
import { Loading } from '../../atoms/Loading';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import { PlayerEditForm } from '../../organisms/PlayerEditForm';

export const PlayerEdit: React.FC = () => {
  const { teamId, playerId } = useParams();

  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
    { label: '詳細', to: `/teams/${teamId}/players/${playerId}` },
    { label: '変更', to: `/teams/${teamId}/players/${playerId}/edit` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchData, data, loading } = useFetch<PlayerDetailDto>();
  useEffect(() => {
    fetchData(ENDPOINT.PLAYER(teamId as string, playerId as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !data) return <Loading />;
  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="プレイヤーを変更する">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <PlayerEditForm targetPlayer={data} onComplete={(data: PlayerDetailDto) => {
        toast.success('プレイヤーを変更しました。');
        navigate(`/teams/${teamId}/players/${data.parentId}#latest`);
      }} />
    </div>
  );
};
