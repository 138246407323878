import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { TeamDto, UserDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import ItemList from '../../molecules/ItemList';
import { useFetch, useUpdate } from '../../../utils/api';
import { Loading } from '../../atoms/Loading';
import { Modal } from '../../organisms/Modal';
import { toast } from 'react-toastify';
import { SelectInput } from '../../atoms/SelectInput';
import { TeamIcon } from '../../atoms/TeamIcon';

export const TeamDetail: React.FC = () => {
  const { teamId } = useParams();
  const breadcrumbItems = [
    { label: 'チーム', to: `/admin/teams` },
    { label: '詳細', to: `/admin/teams/${teamId}` },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteTargetUserId, setDeleteTargetUserId] = useState<string | null>(null);
  const [addTargetUserId, setAddTargetUserId] = useState('');

  const { execFetch: fetchTeamDetail, data: teamDetail, loading: teamDetailLoading } = useFetch<{ team: TeamDto, users: UserDto[] }>();
  const { execFetch: fetchUsers, data: users, loading: usersLoading } = useFetch<UserDto[]>();

  const { execUpdate: createBelonging, submitting: creating } = useUpdate<null, null>({
    method: 'POST',
    onComplete: async () => {
      toast.success('ユーザーを追加しました。');
      await fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    },
  });

  const { execUpdate: deleteBelonging, submitting: deleting } = useUpdate<null, null>({
    method: 'DELETE',
    onComplete: async () => {
      toast.success('ユーザーを除外しました。');
      await fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    },
    onFailure: (e) => {
      toast.error('ユーザーを除外できませんでした。');
    },
  });

  useEffect(() => {
    fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    fetchUsers(`${ENDPOINT.USERS}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (teamDetailLoading) return <Loading />;

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title={teamDetail?.team.name ?? ""}>
            <TeamIcon />
          </Title>
        </div>
      </div>
      <div className="mt-6">
        <PrimaryButton fullWidth text="ユーザーを追加する" onClick={() => setIsModalOpen(true)} />
      </div>
      <div className='mt-4'>
        {
          !teamDetail ? (
            <Loading />
          ) : teamDetail.users.length > 0 ? (
            <ItemList
              items={teamDetail.users.map((user) => ({ id: user.id, label: `${user.name} - ${user.email}`, createdAt: user.createdAt }))}
              onItemClick={(id) => {
                setDeleteTargetUserId(id);
              }}
            />
          ) : (null)
        }
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="ユーザーを追加する">
        <form className="space-y-4">
          {
            usersLoading || !users ? (
              <Loading />
            ) : (
              <SelectInput
                value={addTargetUserId}
                options={[{ label: 'ユーザーを選択してください', value: '' }, ...users.filter((user) => !teamDetail?.users.some((teamUser) => teamUser.id === user.id)).map((user) => ({ label: `${user.name} - ${user.email}`, value: user.id }))]}
                onChange={(value) => setAddTargetUserId(value)}
              />
            )
          }
          <PrimaryButton disabled={addTargetUserId.length === 0 || creating} loading={creating} fullWidth text="ユーザーを追加する" onClick={async () => {
            await createBelonging(null, ENDPOINT.TEAM_BELONGING(teamId as string, addTargetUserId));
            setIsModalOpen(false);
          }} />
        </form>
      </Modal>
      <Modal isOpen={!!deleteTargetUserId} onClose={() => setDeleteTargetUserId(null)} title="ユーザーを除外する">
        <form className="space-y-4">
          <p>「{users?.find((user) => user.id === deleteTargetUserId)?.name}」を除外します。よろしいですか。</p>
          <PrimaryButton disabled={!deleteTargetUserId || deleting} loading={deleting} fullWidth text="ユーザーを除外する" onClick={async () => {
            await deleteBelonging(null, ENDPOINT.TEAM_BELONGING(teamId as string, deleteTargetUserId as string));
            setDeleteTargetUserId(null);
          }} />
        </form>
      </Modal>
    </div>
  );
};
