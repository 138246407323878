import React from 'react';
import { SlMenu } from "react-icons/sl";

// ボタンのプロパティ型定義
interface MenuButtonProps {
  onClick: () => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={() => {
        onClick();
      }}
      className={`p-2 text-blue-500 bg-white font-bold rounded-full`}
    >
      <SlMenu size={20} />
    </button>
  );
};

export default MenuButton;