import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/pages/Login';
import { AuthLayout } from './components/templates/AuthLayout';
import { AuthTeamLayout } from './components/templates/AuthTeamLayout';
import { PlayerNew } from './components/pages/team/PlayerNew';
import { PlayerList } from './components/pages/team/PlayerList';
import { PlayerDetail } from './components/pages/team/PlayerDetail';
import { TeamSelect } from './components/pages/team/TeamSelect';
import { AuthAdminLayout } from './components/templates/AuthAdminLayout';
import { TeamList } from './components/pages/admin/TeamList';
import { TeamDetail } from './components/pages/admin/TeamDetail';
import { SystemPlayerSelect } from './components/pages/admin/SystemPlayerSelect';
import { SystemPlayerDetail } from './components/pages/admin/SystemPlayerDetail';
import { PlayerEdit } from './components/pages/team/PlayerEdit';
import CommandNew from './components/pages/team/CommandNew';
import { CommandDetail } from './components/pages/team/CommandDetail';
import ScrollToTop from './components/organisms/ScrollToTop';
import { CommandResultEdit } from './components/pages/team/CommandResultEdit';
import { CommandList } from './components/pages/team/CommandList';
import Landing from './components/pages/Landing';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivaryPolicy';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        toastClassName={() =>
          "text-black font-medium bg-white p-2"
        }
        closeButton={false}
      />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privary-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/teams"
          element={
            <AuthLayout>
              <TeamSelect />
            </AuthLayout>
          }
        />
        <Route
          path="/admin"
          element={
            <AuthLayout>
              <AuthAdminLayout />
            </AuthLayout>
          }
        >
          <Route path="" element={<Navigate to="/admin/teams" />} />
          <Route path="teams" element={<TeamList />} />
          <Route path="teams/:teamId" element={<TeamDetail />} />
          <Route path="system-players" element={<SystemPlayerSelect />} />
          <Route path="system-players/:code" element={<SystemPlayerDetail />} />
        </Route>

        <Route
          path="/teams/:teamId"
          element={
            <AuthLayout>
              <AuthTeamLayout />
            </AuthLayout>
          }
        >
          <Route path="players" element={<PlayerList />} />
          <Route path="players/new" element={<PlayerNew />} />
          <Route path="players/:playerId" element={<PlayerDetail />} />
          <Route path="players/:playerId/edit" element={<PlayerEdit />} />
          <Route path="players/:playerId/commands/new" element={<CommandNew />} />
          <Route path="players/:playerId/commands/:commandId" element={<CommandDetail />} />
          <Route path="players/:playerId/commands/:commandId/results/:commandResultId" element={<CommandDetail />} />
          <Route path="players/:playerId/commands/:commandId/results/:commandResultId/edit" element={<CommandResultEdit />} />
          <Route path="commands" element={<CommandList />} />
        </Route>

        <Route path="*" element={<Navigate to="/teams" />} />
      </Routes>
    </Router>
  );
};

export default App;