import React from 'react';

interface LinkButtonProps {
  text: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ text, onClick, loading = false, disabled = false, fullWidth = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      className={`text-black transition underline ${loading || disabled ? 'cursor-not-allowed text-gray-400' : 'hover:text-gray-600'} ${fullWidth ? 'w-full' : ''}`}
    >
      {loading ? '送信中...' : text}
    </button>
  );
};
