import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import liff from '@line/liff';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [viewportHeight, setViewportHeight] = useState('100vh');

  const handleLogin = () => {
    if (!liff.isLoggedIn()) {
      liff.login();
    }
  };

  useEffect(() => {
    const initLiff = async () => {
      try {
        await liff.init({ liffId: process.env.REACT_APP_LIFF_ID as string });
        if (liff.isLoggedIn()) {
          navigate('/privacy-policy');
        }
      } catch (error) {
        console.error('LIFF initialization failed', error);
      }
    };

    initLiff();
  }, [navigate]);

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(`${window.innerHeight}px`);
    };

    // 初期設定
    updateViewportHeight();

    // リサイズイベントリスナーを追加
    window.addEventListener('resize', updateViewportHeight);

    // クリーンアップ関数
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);


  return (
    <div
      className="flex justify-center items-center"
      style={{ height: viewportHeight, overflow: 'hidden' }}
    >
      <div className="space-y-2 text-center">
        <div className='flex flex-col items-center'>
          <img width={150} src={logo} alt="players" />
        </div>
        <h1 className="text-3xl font-bold mb-8">StoryForge</h1>
        <button
          onClick={handleLogin}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          LINEでログイン
        </button>
        {/* <div className="text-xl">
          <div>
            <button
              onClick={handleLogin}
              className="flex items-center justify-center space-x-2 bg-white text-gray-700 font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              <FcGoogle className="size-7" />
              <span>Googleでログイン</span>
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
