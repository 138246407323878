import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { CommandDto } from '../../../dtos';
import { toast } from 'react-toastify';
import { CommandIcon } from '../../atoms/CommandIcon';
import { CommandCreateForm } from '../../organisms/CommandCreateForm';

const CommandNew: React.FC = () => {
  const { teamId, playerId } = useParams<{ teamId: string, playerId: string }>();
  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
    { label: '詳細', to: `/teams/${teamId}/players/${playerId}` },
    { label: '新規リクエスト', to: `/teams/${teamId}/players/${playerId}/commands` },
  ];
  const navigate = useNavigate();
  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="新しくリクエストする">
            <CommandIcon />
          </Title>
        </div>
      </div>
      <CommandCreateForm onComplete={(data: CommandDto) => {
        toast.success('リクエストを受け付けました。');
        navigate(`/teams/${teamId}/players/${playerId}/commands/${data.id}`);
      }} />
    </div>
  );
};

export default CommandNew;