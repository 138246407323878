import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { CommandListItemDto, PagingDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import { Loading } from '../../atoms/Loading';
import { useFetch } from '../../../utils/api';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import CommandItemList from '../../molecules/CommandItemList';
import Paging from '../../molecules/Paging';

const defaultPage = 1;
const defaultPageSize = 50;

export const CommandList: React.FC = () => {
  const { teamId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const breadcrumbItems = [
    { label: 'リクエスト履歴', to: `/teams/${teamId}/commands` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchParentCommands, data: pagingData } = useFetch<PagingDto<CommandListItemDto>>();

  useEffect(() => {
    let page = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
    page = isNaN(page) ? defaultPage : page;
    let pageSize = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
    pageSize = isNaN(pageSize) ? defaultPageSize : pageSize;
    fetchParentCommands(`${ENDPOINT.PARENT_COMMANDS(teamId as string)}?page=${page}&pageSize=${pageSize}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (!pagingData) return <Loading />;

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="リクエスト履歴">
            <PlayerIcon />
          </Title>
        </div>
        <div className='z-50 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
          <PrimaryButton fullWidth text="プレイヤー一覧へ" onClick={() => navigate(`/teams/${teamId}/players`)} />
        </div>
      </div>
      <div>
        {
          !pagingData ? (
            <Loading />
          ) : pagingData.items.length > 0 ? (
              <>
                <Paging
                  data={pagingData}
                  onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                />
                <CommandItemList
                  data={pagingData}
                  onItemClick={(item) => {
                    navigate(`/teams/${teamId}/players/${item.playerId}/commands/${item.id}`);
                  }}
                />
                <Paging
                  data={pagingData}
                  onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                />
              </>
          ) : (null)
        }
      </div>
    </div>
  );
};
