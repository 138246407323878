import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthLayout';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { Outlet } from 'react-router-dom';

export const AuthAdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  if (!["hatakeyama@viable.jp", "ohya@viable.jp"].includes(auth?.user?.email || '')) {
    return (
      <div>
        <p className='pb-2 text-xl text-center'>アクセスする権限がありません。</p>
        <PrimaryButton fullWidth onClick={() => navigate('/teams')} text='所属チームを確認する' />
      </div>
    );
  }
  return (
    <div className='px-2 py-8'>
      <Outlet />
    </div>
  );
};
