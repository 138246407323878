import React from 'react';

interface TextInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  errorMessages?: string[];
}

export const TextInput: React.FC<TextInputProps> = ({ value, onChange, placeholder, errorMessages = [] }) => {
  const hasError = errorMessages.length > 0;

  return (
    <div>
      <input
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        className={`w-full p-2 border ${hasError ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 
          ${hasError ? 'focus:ring-red-500' : 'focus:ring-gray-500'}`}
      />
      {hasError && (
        <ul className="mt-1 text-sm text-red-500">
          {errorMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
