import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { ENDPOINT } from './const';
import { handleApiResponse } from './utils/api';

const firebaseConfig = {
  apiKey: "AIzaSyCsz1-qGn-KJs8aENgrGRk2ULj33McF2so",
  authDomain: "story-forge-4799c.firebaseapp.com",
  projectId: "story-forge-4799c",
  storageBucket: "story-forge-4799c.appspot.com",
  messagingSenderId: "199744329141",
  appId: "1:199744329141:web:c09f9d4d0dbd7b247835fc",
  measurementId: "G-XMD9MPHRJC"
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

export const loginWithGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const idToken = await result.user.getIdToken();
  return await fetch(ENDPOINT.LOGIN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ idToken }),
    credentials: 'include',
  });
};

// ログアウト処理
export const logout = async (onComplete: () => void) => {
  const auth = getAuth();
  await signOut(auth);
  const response = await fetch(ENDPOINT.LOGOUT, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const isResponseOk = await handleApiResponse(response);
  if (!isResponseOk) return;
  onComplete();
};


export { auth, provider };