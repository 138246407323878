import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Breadcrumb } from '../../atoms/Breadcrumb';
import { toast } from 'react-toastify';
import { PlayerDetailDto } from '../../../dtos';
import { PlayerCreateForm } from '../../organisms/PlayerCreateForm';
import { PlayerIcon } from '../../atoms/PlayerIcon';

export const PlayerNew: React.FC = () => {
  const { teamId } = useParams();
  const breadcrumbItems = [
    { label: 'プレイヤー一覧', to: `/teams/${teamId}/players` },
    { label: 'プレイヤーを作る', to: `/teams/${teamId}/players/new` },
  ];
  const navigate = useNavigate();

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb items={breadcrumbItems} />
          <Title title="プレイヤーを作る">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <PlayerCreateForm onComplete={(data: PlayerDetailDto) => {
        toast.success('プレイヤーを作成しました。');
        navigate(`/teams/${teamId}/players/${data.id}`);
      }} />
    </div>
  );
};
